import styled from "@emotion/styled"
import { breakpoints, colors } from "../../styles"

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding-left: 30px;
  width: 100%;
`

export const SearchWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 1000px;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  background: white;
  max-width: 600px;
  margin: auto;
  padding: 30px 0;
`

export const SearchResultWrapper = styled.div`
  margin-top: 50px;
  display: flex;
  width: 100%;
  text-align: center;
  align-items: center;
  justify-content: flex-start;
  max-width: 1000px;
  flex-wrap: wrap;
`

export const ListWrapper = styled.div`
  margin-top: 50px;
  display: flex;
  width: 100%;
  text-align: center;
  align-items: center;
  justify-content: flex-start;
  max-width: 1000px;
  flex-wrap: wrap;
`

export const ProductThumbnail = styled.div`
  background: white;
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin: 10px;
  padding: 20px;
  cursor: pointer;
  border-radius: 5px;
  transition-duration: 0.2s;
  &:hover {
   transform: scale(1.1);
   box-shadow: 0 0 13px 6px #0000000d;
  }
`

export const DeleteButton = styled.button`
  display: flex;
  z-index: 8;
  position: absolute;
  top: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  color: ${colors.warning};
  font-size: 10px;
  background: #fafafa;
  padding: 10px;
  line-height: 0;
  font-size: 13px;
  font-weight: 100;
  border-top-left-radius: 5px;
  border-bottom-right-radius: 5px;
  cursor: pointer;
  transition-duration: 0.3s;
  &:hover {
    background: ${colors.warning};
    color: white;
  }
`

export const TitleProductCollection = styled.span`
  font-size: 1rem;
  color: ${colors.darkGrey};
`

export const TitleProductType = styled.span`
  font-size: 0.9rem;
  color: ${colors.softGrey};
`

export const TitleProductGemMetal = styled.span`
  font-size: 0.9rem;
  color: ${colors.softGrey};
`

export const ImageThumb = styled.div`
  min-width: 170px;
  min-height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ImageFeatured = styled.img`
  max-width: 150px;
  @media (max-width: ${breakpoints.tablet}px) {
    max-width: 17vw;
  }
`
