import React, { useEffect, useState } from "react"
import { Link, navigate } from "gatsby"

import {
  Divider,
  HeadingTitle,
  MainContainer,
  WrapperHeading,
  WrapperHeadingTitle, WrapperProductEmpty,
  WrapperProductList
} from "./StyledWishList"
import { colors } from "../styles"
import { FaHeart } from "react-icons/fa"
import { AiOutlineDelete } from "react-icons/ai"
import {
  DeleteButton,
  ImageFeatured,
  ImageThumb,
  ProductThumbnail, SearchResultWrapper,
  TitleProductCollection, TitleProductGemMetal, TitleProductType
} from "../Admin/EditProduct/StyledEditProduct"
import { createProductLink } from "../../utils/productHelpers"

export default ({ wishListItems, removeItem }) => {

  const handleSelected = (e, item) => {
    e.preventDefault()
    let productUrl = createProductLink(item)
    navigate(`/${productUrl}`)
  }

  const renderItem = (listItems) => listItems.map((item, id) =>
    <ProductThumbnail key={id}>
      <DeleteButton onClick={(e) => removeItem(e, item.id)}>
        <AiOutlineDelete size={16}/>
      </DeleteButton>
      <ImageThumb onClick={(e) => handleSelected(e, item)}>
        <ImageFeatured alt={"resultat de recherche"} src={process.env.IMAGE_ENDPOINT + item.images[0].id + "_SMALL.png"}/>
      </ImageThumb>
      <TitleProductCollection>
        {item.collection}
      </TitleProductCollection>
      <TitleProductType>
        {item.attributeType}
      </TitleProductType>
      <TitleProductGemMetal>
        {item.attributeGem} {item.attributeMetal}
      </TitleProductGemMetal>
    </ProductThumbnail>
  )

  return (
    <MainContainer>
      <WrapperHeading>
        <WrapperHeadingTitle>
          <FaHeart size={25} color={colors.darkGrey}/>
          <HeadingTitle>Ma liste d'envies</HeadingTitle>
        </WrapperHeadingTitle>
        <Divider/>
      </WrapperHeading>
      {wishListItems.length > 0 ? (
        <WrapperProductList>
          {renderItem(wishListItems)}
        </WrapperProductList>
      ) : (
        <WrapperProductEmpty>
          Votre sélection est vide.
        </WrapperProductEmpty>
        )}

    </MainContainer>
  )
}
