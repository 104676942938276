import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import WishListContainer from "../components/WishList"
import { isLoggedIn } from "../utils/auth"
import { dimensions } from "../components/styles"
import styled from "@emotion/styled"
import { getWishListCookie, removeItemWishListCookie } from "../utils/productHelpers"


export const MainWrapper = styled.div`
  max-width: ${dimensions.contentWidth};
  display: flex;
  justify-content: center;
  margin: auto;
  padding: 50px 20px;
`


export default ({data}) => {

  const listProducts = data.WaskollData.listProducts.items
  const [userWishList, setUserWishList] = useState([])


  useEffect(() => {
    if (!isLoggedIn()) {
      const currentCookie = getWishListCookie()
      if (currentCookie) {
        let wishList = listProducts.filter(item => currentCookie.some(itemCookie => itemCookie === item.id))
        setUserWishList(wishList)
      }
    }

  }, [])

  const handlerRemoveItem = (e, item) => {
    e.preventDefault()
    if (!isLoggedIn()) {
      removeItemWishListCookie(item)
      let newWishList = userWishList.filter(wishListItem => wishListItem.id !== item)
      setUserWishList(newWishList)
    }
  }

  return (
    <MainWrapper>
      <WishListContainer removeItem={handlerRemoveItem} wishListItems={userWishList}/>
    </MainWrapper>
  )
}

export const query = graphql`
    query AllProductsQuery {
        WaskollData {
            listProducts {
                items {
                    id
                    attributeGem
                    attributeMetal
                    attributeType
                    collection
                    images {
                        id
                    }
                    ref
                }
            }
        }
    }
`
